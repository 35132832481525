import { cn } from "@/lib/utils";
import { ReactNode } from "react";
import Link from "../ui/link";
import Logo from "./logo";

const Wrapper = ({
  children,
  className,
  href,
}: {
  children: ReactNode;
  className: string;
  href?: string;
}) =>
  href ? (
    <Link href={href} className={className}>
      {children}
    </Link>
  ) : (
    <div className={className}>{children}</div>
  );

export default function Banner({
  className,
  withLogo,
  href,
}: {
  href?: string;
  className?: string;
  withLogo?: boolean;
}) {
  return (
    <Wrapper
      href={href}
      className={cn(
        "flex items-center text-3xl sm:text-3xl md:text-3xl font-semibold text-primary gap-2 hover:no-underline font-newOrder transition-all group max-w-fit",
        className,
        href && "group",
      )}
    >
      {withLogo && (
        <Logo className="w-10 h-8 group-hover:rotate-12 transition-all" />
      )}
      uproots
    </Wrapper>
  );
}

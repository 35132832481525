import LoadingSpinner from "@/components/loading-states/loading-spinner";
import { t } from "i18next";
import { forwardRef } from "react";
import { Button, ButtonProps } from "../button";
import InfoTooltip from "../info-tooltip";

interface SubmitButtonProps extends ButtonProps {
  loading?: boolean;
  loadingText?: string;
  tooltip?: string | boolean;
}

const SubmitButton = forwardRef<HTMLButtonElement, SubmitButtonProps>(
  (
    { children, loading, tooltip, loadingText = t("loading"), ...rest },
    ref,
  ) => (
    <Button
      {...rest}
      disabled={rest.disabled || loading}
      size={rest.size || "default"}
    >
      {loading && <LoadingSpinner />}
      {(loading && loadingText) || children}
      {tooltip && <InfoTooltip info={tooltip} />}
    </Button>
  ),
);
SubmitButton.displayName = "SubmitButton";

export default SubmitButton;

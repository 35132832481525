// JsFromRoutes CacheKey 706d45900ef9faafb4223531b5790b26
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/'),
  tos: /* #__PURE__ */ definePathHelper('get', '/tos'),
  privacy: /* #__PURE__ */ definePathHelper('get', '/privacy'),
  cookiesPolicy: /* #__PURE__ */ definePathHelper('get', '/cookies_policy'),
}

import FadeIn from "@/animations/fade-in";
import Logo from "@/components/icons/logo";
import { TooltipProvider } from "@/components/ui/tooltip";
import { PropsWithChildren, ReactNode } from "react";
import CookiesConsent from "./components/cookies-consent";
import { CallToAction } from "./components/landing/call-to-action";
import Topbar from "./components/landing/topbar";
import LanguageProvider from "./providers/language-provider";
import SentryProvider from "./providers/sentry-provider";

interface SsrLayoutProps extends PropsWithChildren {}

const FloatingCta = () => (
  <CallToAction className="hidden sm:flex sm:fixed bottom-8 right-8 z-40 shadow-sm !text-md sm:py-4 sm:px-4 animate-fade-up animate-delay-1000">
    <Logo className="w-6" />
    Try it for free
  </CallToAction>
);

const SsrLayout = ({ children }: SsrLayoutProps) => {
  return (
    <SentryProvider>
      <LanguageProvider>
        <TooltipProvider>
          <div className="w-full min-h-screen bg-background max-w-[1500px] m-auto">
            <CookiesConsent />
            <Topbar />
            <FloatingCta />
            <FadeIn>{children}</FadeIn>
          </div>
        </TooltipProvider>
      </LanguageProvider>
    </SentryProvider>
  );
};

export default (page: ReactNode) => <SsrLayout>{page}</SsrLayout>;

import { pricing } from "@/api";
import Icon from "@/components/ui/icon";
import Link from "@/components/ui/link";
import useCurrentSubscription from "@/lib/hooks/use-current-subscription";
import { cn } from "@/lib/utils";
import { StripeSubscription } from "@/types/serializers";
import { t } from "i18next";
import { forwardRef } from "react";
import { Trans } from "react-i18next";
import { ManageBillingButton } from "./billing/show";

type PaywallProps = {
  feature: StripeSubscription["entitlements"][0];
};

export default function Paywall({ feature }: PaywallProps) {
  return (
    <div className="flex flex-col gap-4 h-full items-center justify-center p-12">
      <p className="max-w-md text-center">
        <Trans
          i18nKey="paywall.message"
          components={{ br: <br /> }}
          values={{
            feature: t(`pricing.features.${feature}`).toLocaleLowerCase(),
          }}
        />
      </p>
      <UpgradeButton />
    </div>
  );
}

type UpgradeButtonProps = {
  className?: string;
};

export const UpgradeButton = forwardRef<HTMLAnchorElement, UpgradeButtonProps>(
  ({ className }, ref) => {
    const hasSubscriptionIssue = !!useCurrentSubscription()?.issue;

    if (hasSubscriptionIssue) return <ManageBillingButton />;

    return (
      <Link
        href={pricing.show.path()}
        variant="default"
        className={cn(className, "z-20")}
      >
        <Icon name="circle-fading-arrow-up" />
        {t("paywall.button")}
      </Link>
    );
  },
);

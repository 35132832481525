import MainPanel from "@/layouts/components/main-panel";
import useCurrentSubscription from "@/lib/hooks/use-current-subscription";
import { ManageBillingButton } from "@/pages/settings/billing/show";
import { ReactNode } from "react";
import DashboardProviders from "./providers/dashboard-provider";

export const DashboardLayout = ({ children }: { children: ReactNode }) => {
  return (
    <DashboardProviders>
      <SubscriptionIssueBanner />
      <MainPanel>{children}</MainPanel>
    </DashboardProviders>
  );
};

const SubscriptionIssueBanner = () => {
  const issue = useCurrentSubscription()?.issue;

  if (!issue) return null;

  return (
    <div className="w-full bg-destructive text-center px-2 drop-shadow-md py-4 sm:py-2 flex flex-col sm:flex-row gap-4 items-center justify-center sticky top-0 left-0 z-50 sm:h-12">
      <span className="text-sm">{issue}</span>
      <ManageBillingButton size="sm" />
    </div>
  );
};

export default (page: ReactNode) => <DashboardLayout>{page}</DashboardLayout>;

import { cn } from "@/lib/utils";
import { ComponentPropsWithoutRef, ReactNode } from "react";
import Icon, { IconProps } from "./icon";
import { Tooltip } from "./tooltip";

export interface InfoTooltipProps extends ComponentPropsWithoutRef<"span"> {
  info?: ReactNode;
  icon?: IconProps["name"];
  size?: IconProps["size"];
}

export default function InfoTooltip({
  info,
  icon = "info",
  size = 12,
  ...rest
}: InfoTooltipProps) {
  if (!info) return null;

  return (
    <Tooltip content={info}>
      <span {...rest}>
        <Icon
          name={icon}
          size={size}
          className={cn("text-muted-foreground/80 cursor-pointer")}
        />
      </span>
    </Tooltip>
  );
}

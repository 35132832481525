import FadeIn from "@/animations/fade-in";
import { Separator } from "@/components/ui/separator";
import useWindowSize from "@/lib/hooks/use-window-size";
import { cn } from "@/lib/utils";
import { PropsWithChildren, ReactNode } from "react";

export interface SubPageProps extends PropsWithChildren {
  title?: ReactNode;
  subtitle?: ReactNode;
  topNavigation?: ReactNode;
  headerAction?: ReactNode;
  className?: string;
}

export default function SubPage({
  title,
  subtitle,
  topNavigation,
  headerAction,
  className,
  children,
}: SubPageProps) {
  const { isMobile } = useWindowSize();
  return (
    <div className={cn("space-y-6 relative", className)} data-qa="sub-page">
      {topNavigation && <div className="!-mb-2">{topNavigation}</div>}
      <div
        className="flex items-center gap-4 flex-col sm:flex-row"
        data-qa="sub-page-header"
      >
        <div className="flex-grow w-full">
          <h3 className="hidden sm:block text-lg font-medium" data-qa="title">
            {title}
          </h3>
          <div className="text-sm text-muted-foreground" data-qa="subtitle">
            {subtitle}
          </div>
        </div>
        {headerAction}
      </div>
      {(title || subtitle) && <Separator />}
      <FadeIn noFadeIf={isMobile}>{children}</FadeIn>
    </div>
  );
}

import { cn } from "@/lib/utils";
import { Transition } from "@headlessui/react";
import { ComponentPropsWithoutRef, useEffect, useState } from "react";

export interface FadeInProps extends ComponentPropsWithoutRef<"div"> {
  delay?: number;
  shown?: boolean;
  noFadeIf?: boolean;
}

export default function FadeIn({
  children,
  delay,
  shown,
  noFadeIf = false,
  className,
  ...rest
}: FadeInProps) {
  const [show, setShow] = useState(shown ?? false);

  useEffect(() => {
    setTimeout(() => setShow(true), delay ?? 10);
  }, []);

  if (noFadeIf) return <div className={className}>{children}</div>;

  return (
    <Transition
      show={show}
      enter={cn("animate-in fade-in duration-1000")}
      leave="animate-out fade-out duration-1000"
      leaveTo="hidden"
      className={className}
      {...rest}
    >
      {children}
    </Transition>
  );
}

import { cn } from "@/lib/utils";
import { isAdmin } from "@/lib/utils/session-utils";
import { ReactNode } from "react";

export default function MainPanel({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "min-h-screen transition-all bg-moraki-background dark:bg-background",
        className,
      )}
    >
      <div className={cn(!isAdmin() && "sm:w-11/12 m-auto")}>{children}</div>
    </div>
  );
}

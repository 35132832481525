import { cn } from "@/lib/utils";
import { Link as InertiaLink, InertiaLinkProps } from "@moraki/inertia-react";
import { ButtonProps, buttonVariants } from "./button";

interface LinkProps extends Omit<InertiaLinkProps, "size"> {
  variant?: ButtonProps["variant"];
  size?: ButtonProps["size"];
}

export default function Link({ size, variant, ...rest }: LinkProps) {
  if (rest.target === "_blank") {
    return (
      <a
        href={rest.href}
        className={cn(
          buttonVariants({ variant: variant || "link" }),
          rest.className,
        )}
        target={rest.target}
      >
        {rest.children}
      </a>
    );
  }

  return (
    <InertiaLink
      {...rest}
      className={cn(
        buttonVariants({ variant: variant || "link", size: size }),
        rest.className,
      )}
    />
  );
}

import Icon from "@/components/ui/icon";
import { useToast } from "@/components/ui/use-toast";
import { UpgradeButton } from "@/pages/settings/paywall";
import { ToastAction } from "@radix-ui/react-toast";
import { useEffect } from "react";
import useFlash from "./use-flash";
import useFlashNotifications from "./use-flash-notifications";
import usePageProp from "./use-page-prop";

export default function useFlashToasts() {
  const flash = useFlashNotifications();
  const errors = usePageProp<Record<string, string>>("errors");
  const baseError = errors["base"];
  const paywall = useFlash<{ paywall?: string }>().paywall;

  const { toast } = useToast();

  return useEffect(() => {
    if (flash?.success) {
      toast({
        ...flash.success,
        action: flash.success.icon && (
          <Icon name={flash.success.icon} size={48} />
        ),
      });
      return;
    }

    if (flash?.alert) {
      toast({
        ...flash.alert,
        variant: "destructive",
        action: flash.alert.icon && <Icon name={flash.alert.icon} size={48} />,
      });
      return;
    }

    if (paywall) {
      toast({
        title: paywall,
        duration: 5000,
        variant: "destructive",
        className: "w-[500px] -ml-28",
        action: (
          <ToastAction altText={paywall} asChild>
            <UpgradeButton />
          </ToastAction>
        ),
      });
      return;
    }

    if (baseError) {
      toast({
        title: "Error",
        description: baseError,
        variant: "destructive",
      });
    }
  }, [flash]);
}

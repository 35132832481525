import { billing, pricing } from "@/api";
import { Badge } from "@/components/ui/badge";
import { ButtonProps } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import SubmitButton from "@/components/ui/form/submit-button";
import Icon from "@/components/ui/icon";
import Link from "@/components/ui/link";
import SubPage from "@/components/ui/sub-page";
import useAuth from "@/lib/hooks/use-auth";
import useCurrentSubscription from "@/lib/hooks/use-current-subscription";
import { hasFeature } from "@/lib/hooks/use-features";
import usePageProp from "@/lib/hooks/use-page-prop";
import useScopedTranslate from "@/lib/hooks/use-scoped-translate";
import { formatCurrency } from "@/lib/utils/currency-utils";
import { DAY_MONTH_DATE_FORMAT, formatDateTime } from "@/lib/utils/date-utils";
import { isAdmin } from "@/lib/utils/role-utils";
import { StripePrice, StripeProduct } from "@/types/serializers";
import { router } from "@moraki/inertia-react";
import { t } from "i18next";
import { Fragment, useState } from "react";

export const PLAN_ICONS = {
  free: "🪶",
  seed: "🌱",
  sprout: "🌿",
  ripe: "🍇",
  sequoia: "🌲",
} as Record<StripeProduct["id"], string>;

export default function Billing() {
  const product = usePageProp<StripeProduct>("product");
  const price = usePageProp<StripePrice>("price");
  const availableFeatures =
    usePageProp<StripeProduct["features"]>("available_features");
  const st = useScopedTranslate("settings.billing");
  const hasSubscriptionIssue = !!useCurrentSubscription()?.issue;
  const subscription = useCurrentSubscription();

  return (
    <SubPage
      title={st("title")}
      subtitle={st("subtitle")}
      headerAction={!hasSubscriptionIssue && <ManageBillingButton />}
    >
      <Card className="w-full">
        <CardHeader>
          <CardTitle className="text-2xl">
            <div className="flex items-center gap-2 flex-grow">
              {PLAN_ICONS[product.id] || ""} {product.name}
              {subscription?.cancelAt && (
                <Badge variant="secondary">
                  {st("endsOn", {
                    date: formatDateTime(
                      subscription.cancelAt,
                      DAY_MONTH_DATE_FORMAT,
                    ),
                  })}
                </Badge>
              )}
            </div>
            <Link href={pricing.show.path()} variant="secondary">
              {st("changePlan")}
            </Link>
          </CardTitle>
          {subscription?.downgrade && (
            <Badge variant="secondary">
              {st("downgradesToOn", {
                plan: t(`pricing.${subscription.downgrade.price}.name`),
                date: formatDateTime(
                  subscription.downgrade.startDate,
                  DAY_MONTH_DATE_FORMAT,
                ),
              })}
            </Badge>
          )}
        </CardHeader>
        <CardContent>
          <p className="flex gap-2 items-end font-semibold">
            <span className="text-xl">{formatCurrency(price.unitAmount)}</span>
            <span>{t("pricing.perMonth")}</span>
          </p>
          <div className="grid grid-cols-[25px_1fr] mt-4 justify-center items-center">
            <Icon name={"check"} className="text-green-500" />
            <span>{st("credits", { credits: price.product.credits })}</span>

            {availableFeatures.map((feature) => (
              <Fragment key={`feature-${feature}`}>
                <Icon
                  name={hasFeature(feature) ? "check" : "dot"}
                  className={
                    hasFeature(feature)
                      ? "text-green-500"
                      : "text-muted-foreground"
                  }
                />
                <span>{t(`pricing.features.${feature}`)}</span>
              </Fragment>
            ))}
          </div>
        </CardContent>
      </Card>
    </SubPage>
  );
}

export const ManageBillingButton = (props: ButtonProps) => {
  const auth = useAuth();
  const [loadingBilling, setLoadingBilling] = useState(false);
  const st = useScopedTranslate("settings.billing");

  if (!isAdmin(auth?.membership)) return null;

  return (
    <SubmitButton
      {...props}
      onClick={() =>
        router.put(billing.update.path(), undefined, {
          onStart: () => setLoadingBilling(true),
        })
      }
      loading={loadingBilling}
      className="w-full sm:w-auto"
    >
      {st("manage")}
    </SubmitButton>
  );
};

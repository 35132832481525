import i18n from "@/i18n";
import { t } from "i18next";

export const getLocaleCurrency = () => {
  const currency = t("currency");

  const sample = Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency,
  }).formatToParts(100000.1);

  const decimalSeparator = sample.find((part) => part.type === "decimal")
    ?.value;
  const groupSeparator = sample.find((part) => part.type === "group")?.value;
  const symbol = sample.find((part) => part.type === "currency")?.value;

  return {
    name: currency,
    decimalSeparator,
    groupSeparator,
    symbol,
  };
};

export const formatCurrency = (
  cents: number | undefined | null,
  options?: {
    currency?: string;
    notation?: string;
    maximumFractionDigits?: number;
  },
) => {
  if (cents === undefined || cents === null) return;

  const isExact = cents % 100 === 0;

  return t("format.currency", {
    value: cents / 100,
    formatParams: {
      value: {
        currency: (options?.currency || t("currency")).toLocaleUpperCase(),
        maximumFractionDigits: isExact
          ? 0
          : options?.maximumFractionDigits || 2,
        notation: options?.notation,
      },
    },
  });
};

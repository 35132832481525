import { ReactNode } from "react";
import CommonProviders from "./common-providers";

export default function DashboardProvider({
  children,
}: {
  children: ReactNode;
}) {
  return <CommonProviders>{children}</CommonProviders>;
}

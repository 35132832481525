import { cn } from "@/lib/utils";
import Icon, { IconProps } from "../ui/icon";

export default function LoadingSpinner({
  className,
  ...rest
}: Omit<IconProps, "name">) {
  return (
    <Icon
      className={cn(className, "animate-spin")}
      name="loader-circle"
      size={rest.size || 16}
    />
  );
}

import { Toaster } from "@/components/ui/toaster";
import { TooltipProvider } from "@/components/ui/tooltip";
import useWindowSize from "@/lib/hooks/use-window-size";
import {
  createSidebarStore,
  SidebarStoreProvider,
} from "@/lib/stores/sidebar-store";
import { ReactNode, useRef } from "react";
import { QueryParamProvider } from "use-query-params";
import { WindowHistoryAdapter } from "use-query-params/adapters/window";
import CookiesConsent from "../components/cookies-consent";
import LanguageProvider from "./language-provider";
import SentryProvider from "./sentry-provider";
import { ThemeProvider } from "./theme-provider";

export interface CommonProvidersProps {
  children: ReactNode;
  noToasts?: boolean;
}

export default function CommonProviders({
  children,
  noToasts,
}: CommonProvidersProps) {
  const { isMobile } = useWindowSize();
  const sidebarStore = useRef(createSidebarStore({ shown: !isMobile })).current;

  return (
    <ThemeProvider>
      <SidebarStoreProvider value={sidebarStore}>
        <QueryParamProvider adapter={WindowHistoryAdapter}>
          <SentryProvider>
            <LanguageProvider>
              <TooltipProvider>
                <CookiesConsent />
                {!noToasts && <Toaster />}
                {children}
              </TooltipProvider>
            </LanguageProvider>
          </SentryProvider>
        </QueryParamProvider>
      </SidebarStoreProvider>
    </ThemeProvider>
  );
}

import usersSessions from "@/api/Users/SessionsApi";
import Link from "@/components/ui/link";
import { cn } from "@/lib/utils";
import { ReactNode } from "react";

export const CallToAction = ({
  children,
  className,
}: {
  children?: ReactNode;
  className?: string;
}) => (
  <Link
    href={usersSessions.new.path()}
    size="lg"
    variant="default"
    className={cn("!text-lg py-8 rounded-full", className)}
  >
    {children}
  </Link>
);

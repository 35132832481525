import { IconProps } from "@/components/ui/icon";
import usePageProp from "./use-page-prop";

export type Flash = {
  title: string;
  description?: string;
  icon?: IconProps["name"];
};

export default function useFlash<T>() {
  return usePageProp<T>("flash");
}

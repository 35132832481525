// TypesFromSerializers CacheKey 57b102ed78f91eebc688567750a88eeb
//
// DO NOT MODIFY: This file was automatically generated by `bin/rake enums:generate_types`
// to be compatible with files generated with TypesFromSerializers.

type WorkspaceMemberRole = "owner" | "admin" | "member";

export const WorkspaceMemberRoleEnum = {
  owner: "owner",
  admin: "admin",
  member: "member",
} as Record<WorkspaceMemberRole, string>;

export default WorkspaceMemberRole;

import { dateLocale } from "@/i18n";
import { formatRelative } from "date-fns";
import { t } from "i18next";

const DEFAULT_DATE_FORMAT = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
} as Intl.DateTimeFormatOptions;

export const DAY_OF_YEAR_DATE_FORMAT = {
  year: "numeric",
  month: "long",
  day: "numeric",
} as Intl.DateTimeFormatOptions;

export const DATE_TIME_FORMAT = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
} as Intl.DateTimeFormatOptions;

export const SHORT_DATE_FORMAT = {
  day: "2-digit",
  month: "short",
  year: "numeric",
} as Intl.DateTimeFormatOptions;

export const DAY_MONTH_DATE_FORMAT = {
  day: "numeric",
  month: "long",
} as Intl.DateTimeFormatOptions;

export const formatDateTime = (
  isoDate: Date | string | undefined,
  format: Intl.DateTimeFormatOptions = DEFAULT_DATE_FORMAT,
) => {
  if (!isoDate) return;

  const date = castToDate(isoDate);

  return t("format.dateTime", {
    value: date,
    formatParams: {
      value: format,
    },
  });
};

export const castToDate = (isoDate: Date | string | undefined) =>
  typeof isoDate === "string" ? new Date(isoDate) : isoDate;

export const distanceToNow = (isoDate: Date | string | undefined) => {
  const date = castToDate(isoDate);

  if (!date) return;

  return formatRelative(date, new Date(), { locale: dateLocale() });
};

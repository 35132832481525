import { WorkspaceMembership } from "@/types/serializers";
import WorkspaceMemberRole, {
  WorkspaceMemberRoleEnum,
} from "@/types/WorkspaceMemberRole";

const ADMIN_ROLES = [
  WorkspaceMemberRoleEnum.admin,
  WorkspaceMemberRoleEnum.owner,
] as WorkspaceMemberRole[];

export function isAdmin(membership?: WorkspaceMembership) {
  return membership && ADMIN_ROLES.includes(membership.role);
}

import { StripeSubscription } from "@/types/serializers";
import useCurrentSubscription from "./use-current-subscription";

export default function useEntitlements() {
  const currentSubscription = useCurrentSubscription();
  return (
    currentSubscription?.entitlements ||
    ([] as StripeSubscription["entitlements"])
  );
}

export const hasFeature = (feature: StripeSubscription["entitlements"][0]) => {
  const entitlements = useEntitlements();

  return entitlements?.includes(feature) ?? false;
};
